import 'date-fns';
import React, { ReactNode, useState } from 'react';
import { map, find, includes } from 'lodash';
import { RegisterOptions } from 'react-hook-form/dist/types';
import { useFormContext } from 'react-hook-form';

import {
    Grid,
    GridSize,
    Switch,
    TextField,
    MenuItem,
    Divider,
    StyledComponentProps,
} from '@material-ui/core';

import { useWindowSize } from '../../../custom-hooks';

import { DropDownItems } from '../../controls/dropdowns/dropdown';
import { AcceptableValue } from './editable-user-data';
import { useJakhubTranslation } from '../../../i18n/jakhub-translation';

// COMBO BOX TO BE FIXED
// import ComboBox from '../../controls/combobox/combobox';

import CheckBox from '../../controls/checkbox/checkbox';
import DatePicker from '../../../../src/components/controls/date-picker/date-picker';
import DivButton from '../legos/div-button/div-button';
import FactoryDateRangePicker from './legos/factory-date-range-picker';
import ImageUploader from '../../controls/image-uploader/image-uploader';
import PasswordStrength from '../../authentication/legos/password-strength';

import styles from './field-factory.module.scss';

type FieldErrorsProps = {
    fieldId: string;
    errors: any;
};
function FieldErrors(props: FieldErrorsProps) {
    const { t } = useJakhubTranslation();
    const { errors, fieldId } = props;
    return errors?.[fieldId]?.types ? (
        <div key={`${fieldId}_error_container`} className={styles.fieldErrorMessage}>
            {map(errors?.[fieldId]?.types, (error, type) => {
                const errorMessage =
                    error?.indexOf?.('I18N:') !== -1 && typeof error === 'string'
                        ? t(error?.replace?.('I18N:', ''))
                        : t('common:labels.missingRequiredField');

                return (
                    <div key={`${fieldId}_${type}`}>
                        <small key={`${fieldId}_${type}_error`}>{errorMessage}</small>
                        <br key={`${fieldId}_${type}_linebreak`} />
                    </div>
                );
            })}
        </div>
    ) : (
        <small key={`${fieldId}_${errors?.[fieldId]?.type}`}>{errors?.[fieldId]?.message}</small>
    );
}
export enum FactoryTextFields {
    TextField = 'TextField',
    TextFieldPassword = 'TextFieldPassword',
    TextFieldPasswordWithStrength = 'TextFieldPasswordWithStrength',
    ReadOnlyTextField = 'ReadOnlyTextField',
    MultiLineTextField = 'MultiLineTextField',
}

export type FactoryTextFieldTypes = keyof typeof FactoryTextFields;

export type GeneratorFieldType =
    | FactoryTextFieldTypes
    | 'CheckBox'
    | 'Spacer'
    | 'LineSpacer'
    | 'SimpleSpacer'
    | 'SimpleDiv'
    | 'TextFieldDropDown'
    | 'ComboBox'
    | 'DatePicker'
    | 'DateRangePicker'
    | 'FileUploader'
    | 'SwitchToggle'
    | 'ImageUploader';
type ValueType = string | boolean | DropDownItems | any;
export type EditableFieldProps = {
    id: string;
    ids?: string[];
    fieldType?: GeneratorFieldType;
    fieldConfig?: any;
    value?: ValueType;
    values?: any; // This should be going away with HUB-1278
    optionList?: DropDownItems;
    onChange?: (id: string, val?: AcceptableValue) => void;
    label?: string | ReactNode;
    className?: string;
    fieldWidth?: GridSize;
    conditionalVisibility?: (data?: any) => boolean;
    conditionalValidation?: (data?: any) => any;
    partnerField?: string;
    validation?: RegisterOptions;
    fieldOnChange?: (newValue?: ValueType) => void;
    parentOnChange?: (newValue?: string) => any;
    disabled?: boolean;
    InputProps?: StyledComponentProps<any>;
};
export interface FieldGeneratorProps {
    dictionaryBase?: string;
    label?: string;
    generatorFields: EditableFieldProps[];
    onChange?: (id: string, val: AcceptableValue) => void;
}
export function EditableFieldGenerator(props: FieldGeneratorProps) {
    const { t, tt } = useJakhubTranslation();
    const {
        register,
        formState: { errors },
    } = useFormContext();
    const {
        dictionaryBase,
        generatorFields,
        onChange: onChangeDefault = () => {
            console.warn('NO ONCHANGE FUNCTION SPECIFIED\nYou probably want to fix this');
        },
    } = props;
    const { isMobile } = useWindowSize();

    return (
        <Grid container alignItems='center' justifyContent='flex-start' spacing={2}>
            {map(generatorFields, (field) => {
                const {
                    id,
                    value,

                    // Complexe data structure for DateRangePicker
                    // Please remove this
                    // HUB-1278
                    values,

                    fieldType,
                    fieldConfig,
                    optionList = [],
                    onChange: onChangeOverride,
                    fieldOnChange,
                    label,
                    className: fieldClassName,
                    fieldWidth = 12,
                    conditionalVisibility,
                    conditionalValidation,
                    partnerField,
                    validation: defaultValidation,
                    disabled = false,
                    InputProps,
                } = field;

                const needsNoLabel = fieldType === 'SwitchToggle';

                const fieldKey = `GeneratedEditableField|${dictionaryBase}|${id}`;
                const gridColumnSpan = isMobile ? 12 : fieldWidth;

                const onChange = onChangeOverride || onChangeDefault;
                const formLabelBase = `${dictionaryBase}.formLabels`;
                const fieldLabel = label ?? (tt(`${formLabelBase}.${id}.label`) as string);
                const fieldPlaceholder = needsNoLabel
                    ? ''
                    : tt(`${formLabelBase}.${id}.placeHolder`);

                const partner = find(generatorFields, (gf) => gf.id === partnerField);
                if (conditionalVisibility) {
                    if (conditionalVisibility(partner?.value)) return null;
                }

                const validation =
                    (conditionalValidation && conditionalValidation(partner?.value)) ||
                    defaultValidation;

                if (fieldType === 'LineSpacer') {
                    return (
                        <Grid key={fieldKey} item xs={gridColumnSpan}>
                            <Divider variant='middle' />
                        </Grid>
                    );
                }

                if (fieldType === 'SimpleSpacer') {
                    return (
                        <Grid key={fieldKey} item xs={gridColumnSpan}>
                            <div style={{ height: '1rem' }} />
                        </Grid>
                    );
                }

                if (fieldType === 'Spacer') {
                    return <Grid key={fieldKey} item xs={gridColumnSpan} />;
                }

                if (fieldType === 'SimpleDiv') {
                    return (
                        <Grid key={fieldKey} item xs={gridColumnSpan} className={fieldClassName}>
                            <span>{label || value || fieldLabel}</span>
                        </Grid>
                    );
                }

                if (fieldType === 'ImageUploader') {
                    const { aspectRatio = '16/9' } = fieldConfig;

                    return (
                        <Grid key={fieldKey} item xs={gridColumnSpan} className={fieldClassName}>
                            <ImageUploader
                                value={value}
                                aspectRatio={aspectRatio}
                                onChange={(newData: AcceptableValue | null) => {
                                    onChange(id, newData);
                                }}
                            />
                        </Grid>
                    );
                }

                if (includes(FactoryTextFields, fieldType)) {
                    const [passwdVisible, setPasswdVisible] = useState(false);
                    const multiline = fieldType === 'MultiLineTextField';
                    const readOnly = fieldType === 'ReadOnlyTextField';
                    const isPassword =
                        fieldType === FactoryTextFields.TextFieldPassword ||
                        fieldType === FactoryTextFields.TextFieldPasswordWithStrength;
                    const showStrength =
                        fieldType === FactoryTextFields.TextFieldPasswordWithStrength;
                    const textFieldType = isPassword && !passwdVisible ? 'password' : 'text';

                    const passwordOrnament = isPassword
                        ? {
                              startAdornment: (
                                  <i
                                      className={`material-icons-outlined ${styles.textFieldOrnamentStart}`}
                                  >
                                      lock
                                  </i>
                              ),
                              endAdornment: (
                                  <DivButton
                                      onClick={() => setPasswdVisible(!passwdVisible)}
                                      className={styles.ornamentButton}
                                  >
                                      <i
                                          className={`material-icons-outlined ${styles.textFieldOrnamentEnd}`}
                                      >
                                          {passwdVisible ? 'visibility' : 'visibility_off'}
                                      </i>
                                  </DivButton>
                              ),
                          }
                        : null;

                    return (
                        <Grid key={fieldKey} item xs={gridColumnSpan}>
                            <TextField
                                inputProps={
                                    multiline
                                        ? { style: { maxHeight: '40vh', overflow: 'auto' } }
                                        : {}
                                }
                                className={fieldClassName}
                                key={`${dictionaryBase}|${id}`}
                                variant={readOnly ? 'standard' : 'outlined'}
                                label={fieldLabel}
                                type={textFieldType}
                                InputProps={{ ...InputProps, ...passwordOrnament }}
                                placeholder={fieldPlaceholder}
                                disabled={readOnly}
                                fullWidth
                                value={value}
                                multiline={multiline}
                                minRows={multiline ? 3 : 1}
                                {...register(id, {
                                    ...validation,
                                    onChange: (event) => {
                                        onChange(id, event.target.value);
                                    },
                                })}
                            />
                            {showStrength ? <PasswordStrength password={value as string} /> : null}
                            <FieldErrors fieldId={id} errors={errors} />
                        </Grid>
                    );
                }

                if (fieldType === 'TextFieldDropDown') {
                    interface ListItemWithKey {
                        key: string | undefined;
                        value: string | undefined;
                    }

                    if (!optionList || optionList.length === 0) {
                        return (
                            <Grid key={fieldKey} item xs={gridColumnSpan}>
                                <div />
                            </Grid>
                        );
                    }

                    if (validation?.deps) {
                        // ToDo This is sketch
                        fieldOnChange && fieldOnChange(value as string);
                    }
                    const isValidValue = find(
                        optionList,
                        (opt: ListItemWithKey) => opt.key === value
                    );
                    const buildOptionList = () => {
                        return [
                            <MenuItem key='item0' value=''>
                                {t('labels.select', { AK_TAG_THING_TO_SELECT: fieldLabel })}
                            </MenuItem>,
                            ...map(optionList, (item: ListItemWithKey) => {
                                return (
                                    <MenuItem key={item.key} value={item.key}>
                                        {item.value}
                                    </MenuItem>
                                );
                            }),
                        ];
                    };

                    return (
                        <Grid key={fieldKey} item xs={gridColumnSpan}>
                            <TextField
                                select
                                fullWidth
                                value={isValidValue ? value : ''}
                                label={fieldLabel}
                                variant='outlined'
                                {...register(id, {
                                    ...validation,
                                    onChange: (event) => {
                                        onChange(id, event.target.value);
                                        fieldOnChange && fieldOnChange(event.target.value);
                                    },
                                })}
                                helperText={errors.currency?.message}
                            >
                                {buildOptionList()}
                            </TextField>
                            <FieldErrors fieldId={id} errors={errors} />
                        </Grid>
                    );
                }

                if (fieldType === 'DatePicker') {
                    const { format, clearable = false } = fieldConfig;

                    return (
                        <Grid key={fieldKey} item xs={gridColumnSpan}>
                            <DatePicker
                                format={format}
                                label={fieldLabel as string}
                                initDate={field.value as string}
                                clearable={clearable}
                                onChange={(newDate: AcceptableValue) => {
                                    onChange(id, newDate?.toString().split('T')[0]);
                                }}
                            />
                        </Grid>
                    );
                }

                if (fieldType === 'DateRangePicker') {
                    // The <Grid /> element is added inside <FactoryDateRangePicker />
                    return (
                        <FactoryDateRangePicker
                            fieldConfig={fieldConfig}
                            key={fieldKey}
                            id={id}
                            dictionaryBase={dictionaryBase}
                            fieldKey={fieldKey}
                            values={values}
                            gridColumnSpan={gridColumnSpan}
                            onChangeOverride={onChangeDefault}
                        />
                    );
                }

                if (fieldType === 'ComboBox') {
                    return (
                        <Grid key={fieldKey} item xs={gridColumnSpan}>
                            COMBO BOX TO BE FIXED
                            {/*<ComboBox*/}
                            {/*    label={fieldLabel}*/}
                            {/*    value={value}*/}
                            {/*    onChange={(newData: AcceptableValue) => {*/}
                            {/*        onChange(id, newData);*/}
                            {/*    }}*/}
                            {/*    optionList={optionList}*/}
                            {/*/>*/}
                        </Grid>
                    );
                }

                if (fieldType === 'CheckBox') {
                    return (
                        <Grid key={fieldKey} item xs={gridColumnSpan}>
                            <CheckBox
                                checked={!!value}
                                label={fieldLabel}
                                onClick={() => onChange(id, !value)}
                            />
                        </Grid>
                    );
                }

                if (fieldType === 'SwitchToggle') {
                    return (
                        <Grid key={fieldKey} item xs={gridColumnSpan} className={fieldClassName}>
                            <div>
                                <Switch
                                    disabled={disabled}
                                    checked={!!value || false}
                                    color='primary'
                                    onChange={(newData: AcceptableValue) => {
                                        onChange(id, newData);
                                    }}
                                />
                            </div>
                            <div style={{ width: '100%' }}>{fieldLabel}</div>
                        </Grid>
                    );
                }

                return (
                    <Grid
                        key={fieldKey}
                        item
                        xs={gridColumnSpan}
                        className={styles.unsupportedFieldType}
                    >
                        <div>{`Cannot render ${id}`}</div>
                        <div>{`Currently unsupported fieldType : ${fieldType}`}</div>
                    </Grid>
                );
            })}
        </Grid>
    );
}
