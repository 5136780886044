import React, { MouseEvent, useEffect, useState, useCallback } from 'react';

import { Markup } from 'interweave';
import { map, isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';
import { useForm, FormProvider } from 'react-hook-form';
import cx from 'classnames';

import { useJakhubTranslation } from '../../../i18n/jakhub-translation';
import { LoginTools, SignupData, UserFormProps } from '../sign-in-up-interfaces';

import { TypeLoginFormData } from '../../../stores/models/login-form-data';
import { TypeApplicationStore } from '../../../stores/application-store';
import { TypeProfileStore } from '../../../stores/profile-store';
import { injectStores } from '../../../stores/factories/store-utils';

import DivButton from '../../common/legos/div-button/div-button';
import Login from '../login/login';
import ResetPassword from '../reset-password/reset-password';
import Signup from '../signup/signup';

import { LOGIN_TOOLS } from '../../../constants';

import styles from './sign-in-up.module.scss';

const { SIGN_UP, RESET_PASSWORD, SIGN_IN } = LOGIN_TOOLS;

const renderUserForm = (userFormProps: UserFormProps) => {
    const {
        formMode,
        signupData,
        handleChange,
        handleCheckboxChange,
        setFormMode,
        submitAttempted,
        setSubmitAttempted,
        postSignUpTargetPath,
        noSubscribe,
        noSignUpOption,
    } = userFormProps;
    if (formMode === SIGN_UP) {
        return (
            <Signup
                noSubscribe={noSubscribe}
                signupData={signupData}
                handleChange={handleChange}
                handleCheckboxChange={handleCheckboxChange}
                setFormMode={setFormMode}
                submitAttempted={submitAttempted}
                setSubmitAttempted={setSubmitAttempted}
                postSignUpTargetPath={postSignUpTargetPath}
            />
        );
    }
    if (formMode === RESET_PASSWORD) {
        return (
            <ResetPassword
                handleChange={handleChange}
                setFormMode={setFormMode}
                submitAttempted={submitAttempted}
                setSubmitAttempted={setSubmitAttempted}
            />
        );
    }
    return (
        <Login
            noSignUpOption={noSignUpOption}
            handleChange={handleChange}
            setFormMode={setFormMode}
            submitAttempted={submitAttempted}
            setSubmitAttempted={setSubmitAttempted}
        />
    );
};

interface ErrorProps {
    ProfileStore: TypeProfileStore;
}
const RenderErrors = injectStores(['ProfileStore'])((props: ErrorProps) => {
    const {
        ProfileStore: { errors },
    } = props;

    if (errors.length === 0) return <div />;
    return (
        <>
            {map(errors, (err: string) => (
                <div className={styles.errors} key={uuid()}>
                    <Markup content={err} />
                </div>
            ))}
        </>
    );
});

interface Props {
    ApplicationStore: TypeApplicationStore;
    ProfileStore: TypeProfileStore;
    inLine?: boolean;
    noSubscribe?: boolean;
    noSignUpOption?: boolean;
    initFormMode?: typeof LOGIN_TOOLS[keyof typeof LOGIN_TOOLS];
    postSignUpTargetPath?: string;
}

function SignInSignUp(props: Props) {
    const { tt } = useJakhubTranslation();
    const {
        ApplicationStore: {
            headerTools: { openedHeaderTool },
            hideAllHeaderTools,
        },
        ProfileStore: { signupResponse, clearState, userLoginFormData, updateUserLoginFormData },
        inLine = false,
        noSubscribe = false,
        noSignUpOption = false,
        initFormMode = SIGN_IN,
        postSignUpTargetPath,
    } = props;

    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [signupData, setSignupData] = useState<SignupData>(userLoginFormData);
    const [formMode, setFormMode] = useState<LoginTools>(
        (openedHeaderTool || initFormMode) as LoginTools
    );

    const formValidator = useForm({
        mode: submitAttempted ? 'onChange' : 'onSubmit',
        criteriaMode: 'all',
    });

    const handleKeyup = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                hideAllHeaderTools();
            }
        },
        [hideAllHeaderTools]
    );

    useEffect(() => {
        setSubmitAttempted(false);
        formValidator.clearErrors();
    }, [formMode]);

    useEffect(() => {
        clearState();
    }, [clearState]);
    useEffect(() => {
        if (!inLine) {
            document.body.style.overflow = 'hidden';
            document.body.addEventListener('keyup', handleKeyup);
        }

        return () => {
            document.body.style.overflow = 'auto';
            document.body.removeEventListener('keyup', handleKeyup);
        };
    }, [handleKeyup]);

    const handleChange = (
        fieldId: keyof TypeLoginFormData,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        updateUserLoginFormData({
            ...userLoginFormData,
            [fieldId]: event.target.value,
        });
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSignupData({ ...signupData, [event.target.name]: event.target.checked });
    };

    const userFormProps = {
        formMode,
        signupData,
        handleChange,
        handleCheckboxChange,
        setFormMode,
        signupResponse,
        hideAllHeaderTools,
        submitAttempted,
        setSubmitAttempted,
        postSignUpTargetPath,
        noSubscribe,
        noSignUpOption,
    };
    const divButtonClass = cx(styles.signInUp, !inLine ? styles.fixedPosition : '');
    const formClass = cx(styles.signInUpForm, inLine ? styles.signInUpFormInLine : '');

    const {
        formState: { errors },
    } = formValidator;
    const formHasErrors = !isEqual(errors, {});

    return (
        <DivButton
            className={divButtonClass}
            onClick={(event: MouseEvent<HTMLDivElement>) => event.stopPropagation()}
        >
            <div className={formClass}>
                <div
                    className={`${styles.signInUpForm__title} ${
                        submitAttempted && formHasErrors ? styles.signInUpForm__error : ''
                    }`}
                >
                    <div>{tt(`menu-tools.signup-login.modeTitle.${userFormProps.formMode}`)}</div>
                </div>
                <div className={styles.signInUpForm__formItem}>
                    <RenderErrors />
                </div>
                <FormProvider {...formValidator}>{renderUserForm(userFormProps)}</FormProvider>
            </div>
            {!inLine && (
                <DivButton className={styles.closeBtn} onClick={hideAllHeaderTools}>
                    <i className='material-icons'>clear</i>
                </DivButton>
            )}
        </DivButton>
    );
}

export default injectStores(['ApplicationStore', 'ProfileStore'])(SignInSignUp);
