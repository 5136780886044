import { cloneDeep, find, forEach, isArray, isEmpty, reduce } from 'lodash';
import { EditableFieldProps } from './field-factory';

export const extractUiData = (generatorFields: EditableFieldProps[], clusterData: any) => {
    const clusterClone = cloneDeep(clusterData);
    if (generatorFields) {
        forEach(clusterClone, (cd, cdId) => {
            const gf = find(generatorFields, (f) => f.id === cdId);
            if (gf?.fieldType === 'TextFieldDropDown') {
                const optionListItem = find(
                    gf?.optionList,
                    (item: { key: string; label: string }) => item.key === cd
                ) as EditableFieldProps;
                clusterClone[`${cdId}Label`] = optionListItem?.value;
            }
        });
    }
    return clusterClone;
};

export function testFieldsEmptiness(value: any, key = ''): boolean {
    if (key?.indexOf('password') !== -1) return false;
    if (typeof value === 'number') return false;
    if (typeof value === 'string') return value.length === 0;
    if (isArray(value)) return value.length === 0;

    if (typeof value === 'object') {
        return reduce(
            value,
            (result, val, key) => {
                return result && testFieldsEmptiness(val, key);
            },
            true
        );
    }

    return isEmpty(value);
}
